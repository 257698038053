import React, {useState, useEffect} from "react";
import {Button, Col, Row, Table, Space, message, Typography, Modal, Input, Checkbox} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import { SketchPicker } from 'react-color';

const breadcrumbs_list = [
    {
        title: "Список типов объявлений",
        url: "/props/advert"
    }
];

export default function PropAdvert() {
    const [update, setUpdate] = useState(0);
    const [list, setList] = useState([]);

    const [element, setElement] = useState({
        display: false,
        id: 0,
        name: ""
    });

    const openModal = (id=0) => {
        if(id > 0) {
            axios.get('/object/type/' + id)
                .then(response => {
                    console.log(response);
                    setElement({
                        ...element,
                        display: true,
                        id: id,
                        name: response.data.name,
                        search: response.data.search,
                        color: response.data.color,
                        viewmain: response.data.viewmain
                    });
                })
                .catch(error => {
                    message.error(error);
                });
        } else {
            setElement({ ...element, display: true });
        }
    };

    const closeModal = () => {
        setElement({
            display: false,
            id: 0,
            name: ""
        });
        setUpdate(update + 1);
    };

    useEffect(() => {
        axios.get('/object/type')
            .then(response => {
                console.log(response);
                setList(response.data);
            })
            .catch(error => {
                message.error(error);
            });
    }, [update]);

    const changePosition = (id, direction) => {
        axios.get('/object/type/?id='+ id +'&'+ direction)
            .then(response => {
                setList(response.data);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const columns = [
        {
            title: "Сортировка",
            dataIndex: "sort",
            key: "sort",
            width: "60px",
            render: (text, record) => (
                <Space size="middle" direction="vertical">
                    <Button onClick={e => changePosition(record.id, "moveUp")}><UpOutlined /></Button>
                    <Button onClick={e => changePosition(record.id, "moveDown")}><DownOutlined /></Button>
                </Space>
            ),
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => openModal(record.id)}>Изменить</Button>
                    <Button type="link">Удалить</Button>
                </Space>
            ),
        },
    ];

    const sendForm = () => {
        if(element.name !== "") {
            let facts = new FormData();
            facts.append("name", element.name);
            facts.append("search", element.search);
            facts.append("color", element.color);
            facts.append("viewmain", Number(element.viewmain));

            let url = "/object/type";

            if(element.id > 0) {
                url = "/object/type/" + element.id;
            }

            axios.post(url, facts)
                .then(success => {
                    closeModal();
                })
                .catch(error => console.log(error));
        } else {
            message.error("Невозможно добавить пустое значение");
        }
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>Список типов объявлений</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="primary" onClick={openModal}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={list} pagination={false} />


                <Modal title="Редактирование типа заявок" visible={element.display} onOk={sendForm} onCancel={closeModal}>
                    <Input
                        placeholder="Наименование"
                        value={element.name}
                        onChange={e => setElement({ ...element, name: e.target.value })}
                    />
                    <Input
                        placeholder="Синонимы"
                        value={element.search}
                        onChange={e => setElement({ ...element, search: e.target.value })}
                    />
                    <SketchPicker
                        color={{hex: element.color}}
                        onChange={e => setElement({ ...element, color: e.hex })}
                    />
                    <br/>
                    <br/>
                    <Checkbox
                        checked={element.viewmain}
                        onChange={e => setElement({...element, viewmain: e.target.checked})}
                    >Показывать на главной (не больше 3 типов)</Checkbox>
                </Modal>
            </div>
        </>
    )
}