import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Col, Row, Typography, Statistic, message, Space, Select, DatePicker} from "antd";

import { UserOutlined } from '@ant-design/icons';
import Bread from "../components/Breadcrumb";
import store from "store";
import Permission from "../components/Permission";

const { Title } = Typography;

export default function Home() {

    const [cities, setCities] = useState([]);
    const [stat, setStat] = useState({});

    const [filter, setFilter] = useState({
        city: null,
        from: "",
        to: ""
    });


    useEffect(() => {
        axios.get('/user/city')
            .then(response => {
                console.log(response);
                setCities(response.data);
            })
            .catch(error => {
                // message.error(error.response.data.message);
            });
    }, []);

    useEffect(() => {
        let url = "/object/stat/";

        if(filter.city !== "") {
            url = url + "?cityid=" + filter.city;
        } else {
            url = url + "?cityid=";
        }

        if(filter.from) {
            url = url + "&datefrom=" + filter.from;
        }
        if(filter.to) {
            url = url + "&dateto=" + filter.to;
        }
        axios.get(url)
            .then(success => {
                console.log(success);
                setStat(success.data);
            })
            .catch(error => {
                    message.error(error.response.data.message);
                }
            )
    }, [filter]);

    const onChangeDateFrom = (date, dateString) => {
        setFilter({ ...filter, from: dateString });
    };

    const onChangeDateTo = (date, dateString) => {
        setFilter({ ...filter, to: dateString });
    };

    const element = (
        <>
            <Bread />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Title>Рабочий стол</Title>
                    </Col>
                    <Col span={12} align={'right'}>

                    </Col>
                </Row>
                <Row>
                    <Space>
                        <Select placeholder="Выберите город" onChange={e => setFilter({ ...filter, city: e })}>
                            {cities.length > 0 && cities.map(item =>
                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            )}
                        </Select>
                        <DatePicker
                            onChange={onChangeDateFrom}
                            format={"YYYY-MM-DD"}
                            placeholder="Дата от"
                        />
                        <DatePicker
                            placeholder="Дата до"
                            format={"YYYY-MM-DD"}
                            onChange={onChangeDateTo}
                        />
                    </Space>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic title="Кол-во заявок" value={stat.countadvert ? stat.countadvert : "0"} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="Кол-во активных заявок" value={stat.countadvertactive ? stat.countadvertactive : "0"} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="Кол-во зарегистрированных пользователей" value={stat.countuser ? stat.countuser : "Нет данных"} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="Среднее кол-во заявок на пользователя" value={stat.srez ? Number(stat.srez).toFixed(2) : 0} />
                    </Col>
                </Row>
            </div>
        </>
    );

    return store.get("user").permission === "admin" ? element : <Permission />
}