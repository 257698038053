import {useState, useEffect} from "react"
import {Button, Col, Input, Row, Typography, Upload, message} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import config from "../../config.json"
import axios from "axios";
import Bread from "../../components/Breadcrumb";
import { Editor } from 'primereact/editor';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

const breadcrumbs_list = [
    {
        title: "Новости",
        url: "/news"
    },
    {
        title: "Редактирование новости"
    }
];

export default function NewsDetail(props) {

    const [update, setUpdate] = useState(0);

    const [item, setItem] = useState({
        name: "",
        anotation: "",
        title: "",
        description: "",
        keywords: "",
        view: 1,
        text: "",
        photo: ""
    });

    useEffect(() => {
        if(props.newsID && props.newsID > 0) {
            axios.get("/news/news/" + props.newsID)
                .then(success => {
                    console.log(success);
                    setItem({
                        name: success.data.name,
                        anotation: success.data.anotation,
                        title: success.data.title,
                        description: success.data.description,
                        keywords: success.data.keywords,
                        view: success.data.view,
                        text: success.data.text,
                        photo: success.data.photo,
                    });
                })
                .catch(error => console.log(error));
        }
    }, [update]);

    const sendForm = (e) => {
        e.preventDefault();

        let facts = new FormData();
        facts.append("name", item.name);
        facts.append("anotation", item.anotation);
        facts.append("text", item.text);
        facts.append("title", item.title);
        facts.append("description", item.description);
        facts.append("keywords", item.keywords);
        facts.append("view", item.view);
        facts.append("photo", item.photo);

        let url = "/news/news";

        if(props.newsID && props.newsID > 0) {
            url = "/news/news/" + props.newsID;
        }

        axios.post(url, facts)
            .then(success => {
                console.log(success);
                if(props.newsID && props.newsID > 0) {
                    setUpdate(update + 1);
                } else {
                    document.location.href="/news";
                }
            })
            .catch(error => console.log(error));
    };

    const propsImage = {
        name: 'file',
        action: config.path + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "news",
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
            }
            if (info.file.status === 'done') {
                console.log(info.file);
                setItem({ ...item, photo: info.file.response.data.url });

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>Редактирование новости</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href="/news">Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={sendForm}>
                    <label htmlFor="">Заголовок новости</label>
                    <Input
                        value={item.name}
                        onChange={(e) => setItem({
                            ...item,
                            name: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Короткое описание новости</label>
                    <Input.TextArea
                        value={item.anotation}
                        onChange={(e) => setItem({
                            ...item,
                            anotation: e.target.value
                        })}
                        required
                    />

                    <label>Текст новости</label>
                    <Editor
                        style={{height:'320px'}}
                        value={item.text}
                        onTextChange={(e) => setItem({
                            ...item,
                            text: e.htmlValue
                        })}
                    />

                    <label htmlFor="">SEO - Title</label>
                    <Input
                        value={item.title}
                        onChange={(e) => setItem({
                            ...item,
                            title: e.target.value
                        })}
                    />
                    <label htmlFor="">SEO - Description</label>
                    <Input
                        value={item.description}
                        onChange={(e) => setItem({
                            ...item,
                            description: e.target.value
                        })}
                    />
                    <label htmlFor="">SEO - Keywords</label>
                    <Input
                        value={item.keywords}
                        onChange={(e) => setItem({
                            ...item,
                            keywords: e.target.value
                        })}
                    />

                    <label htmlFor="">Изображение</label>

                    {item.photo !== "" &&
                        <img src={item.photo} alt="" width={150} />
                    }

                    <Upload {...propsImage}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )
}