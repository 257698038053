import React, {useState, useEffect} from 'react';
import {Button, Col, Row, message, Typography, Input, Select} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";

const { Title } = Typography;
const { Option } = Select;

const breadcrumbs_list = [
    {
        title: "Администраторы",
        url: "/admin"
    },
    {
        title: "Редактирование",
        url: "#"
    }
];

export default function AdminDetail(props) {
    const sha1 = require('js-sha1');

    const [update, setUpdate] = useState(0);
    const [user, setUser] = useState({
        id: 0,
        email: '',
        phone: '',
        password: '',
        password_confirm: '',
        name: '',
        permission: "",
    });

    useEffect(() => {
        if(props.userID && props.userID > 0) {
            axios.get('/admin/admin/' + props.userID)
                .then(response => {
                    console.log(response);
                    setUser({
                        ...user,
                        id: response.data.id,
                        email: response.data.email,
                        phone: response.data.phone,
                        name: response.data.firstname,
                        permission: response.data.permission
                    });
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    }, [update, props]);

    const sendForm = (e) => {
        e.preventDefault();
        console.log(user);

        let facts = new FormData();
        facts.append('email', user.email);
        facts.append('phone', user.phone);
        facts.append('password', user.password);
        facts.append('name', user.name);
        facts.append('permission', user.permission);

        if(user.id > 0) {
            axios.post('/admin/useredit/' + user.id, facts)
                .then(response => {
                    console.log(response);
                    setUpdate(update + 1);
                    message.success("Настройки администратора успешно изменены");
                })
                .catch(error => {
                    message.error(error.response.data.message);
                })
        } else {
            axios.post('/admin/register', facts)
                .then(response => {
                    console.log(response);
                    window.location.href = "/admin";
                })
                .catch(error => {
                    message.error(error.response.data.message);
                })
        }
    };

    const setAdminPermission = (value) => {
        console.log(value);
        setUser({
            ...user,
            permission: value
        })
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Title>Редактирование администратора</Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/admin"}>К списку</Button>
                    </Col>
                </Row>


                <form onSubmit={sendForm}>
                    <label htmlFor="">Email</label>
                    <Input
                        type="email"
                        value={user.email}
                        onChange={(e) => setUser({
                            ...user,
                            email: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Пароль</label>
                    <Input.Password
                        type="password"
                        value={user.password}
                        onChange={(e) => setUser({
                            ...user,
                            password: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Повторите пароль</label>
                    <Input.Password
                        type="password"
                        value={user.password_confirm}
                        onChange={(e) => setUser({
                            ...user,
                            password_confirm: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Имя</label>
                    <Input
                        value={user.name}
                        onChange={(e) => setUser({
                            ...user,
                            name: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Телефон</label>
                    <Input
                        type="text"
                        value={user.phone}
                        onChange={(e) => setUser({
                            ...user,
                            phone: e.target.value
                        })}
                        required
                    />

                    <label htmlFor="">Права доступа</label>
                    <Select style={{width: "200px"}} value={user.permission} onChange={(e) => setAdminPermission(e)}>
                        <Option value={"admin"}>Администратор</Option>
                        <Option value={"moderator"}>Модератор</Option>
                        <Option value={"manager"}>Контент-менеджер</Option>
                    </Select>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>

            </div>
        </>
    )
}