import filtersController from "./filtersController";
import { useEffect } from "react"
import {Button, Col, Row, Table, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";

const FiltersList = observer(() => {
    useEffect(() => {
        filtersController.getFiltersCategories();
    }, []);

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/filters/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/filters/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => filtersController.deleteFilter(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Список категорий фильтров</Typography.Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/filters/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={filtersController.list} />

            </div>
        </>
    )
});

export default FiltersList