import React, {useState, useEffect} from 'react';
import {Button, Col, Row, Table, Space, message, Typography} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";

const { Title } = Typography;

const breadcrumbs_list = [
    {
        title: "Список администраторов",
        url: "/admin"
    }
];

export default function AdminList() {
    const [update, setUpdate] = useState(0);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('/admin/userlist')
            .then(response => {
                console.log(response);
                setUsers(response.data);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }, [update]);

    const deleteAdmin = (id) => {
        if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
            axios.delete('/admin/user/' + id)
                .then(response => {
                    console.log(response);
                    setUpdate(update + 1);
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    };

    const columns = [
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <a href={"/admin/" + record.id}>{text}</a>,
        },
        {
            title: 'Права доступа',
            dataIndex: 'permission',
            key: 'permission',
            render: (text) => {
                if(text === 0) {
                    return(
                        <>Администратор</>
                    )
                } else if(text === 1) {
                    return(
                        <>Модератор</>
                    )
                } else if(text === 2) {
                    return(
                        <>Контент менеджер</>
                    )
                }
            },
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/admin/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => deleteAdmin(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Title>Список администраторов</Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/admin/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={users} />

            </div>
        </>
    )
}