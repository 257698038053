import { useState, useEffect } from "react";
import axios from "axios";
import Bread from "../../components/Breadcrumb";
import {Col, Row, Typography, Dropdown, Button, Menu, Space, Select, Input, Modal} from "antd";
import { DownOutlined } from '@ant-design/icons';
import store from "store"
import {Map, YMaps, Placemark, Circle} from "react-yandex-maps";
import advertStore from "../../store/advertStore";

const breadcrumbs_list = [
    {
        title: "Список заявок",
        url: "/advert"
    },
    {
        title: "Просмотр заявки"
    }
];

const AdvertDetail = (props) => {

    const [update, setUpdate] = useState(0);
    const [item, setItem] = useState({});
    const [user, setUser] = useState({});
    const [cities, setCities] = useState([]);
    const [types, setTypes] = useState({
        type: [],
        region: [],
        room: [],
        typeProperty: [],
        city: []
    });
    const [message, setMessage] = useState({
        display: false,
        message: ""
    });
    const [text, setText] = useState({
        display: false,
        text: ""
    });

    useEffect(() => {
        axios.get("/object/filterdata")
            .then(success => {
                console.log(success);

                axios.get('/user/city')
                    .then(response => {
                        console.log(response);
                        setCities(response.data);
                    });

                let array = [];
                if(success.data.typeproperty.length > 0) {
                    console.log(success.data.typeproperty);
                    success.data.typeproperty.map(item => {
                        array = [...array, {
                            ...item,
                            name: JSON.parse(item.name)
                        }];
                    })
                }

                setTypes({
                    type: success.data.type,
                    region: success.data.region,
                    room: success.data.room,
                    typeProperty: array
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get("/object/object/" + props.advertID)
            .then(success => {
                console.log(success);
                setItem(success.data);
                if(item.filteradvert){
                    item.filteradvert.map(c => {
                        advertStore.getFilterCategory(c.filtercategoryid);
                    });
                }
            });
    }, [update]);

    const editStatus = (status) => {

        let facts = new FormData();
        facts.append("status", status);

        if(text.display === true) {
            setText({ ...text, display: false });
        }

        if(text.text !== "") {
            facts.append("texterror", text.text);
        }

        axios.post("/object/objectdmin/" + props.advertID, facts)
            .then(success => {
                console.log(success);
                setUpdate(update + 1);
            });


    };

    const closeStatus = () => {
        setText({ ...text, display: true });
    };

    const closeModal = () => {
        setText({ text: "", display: false });
    };

    const closeModalMessage = () => {
        setMessage({ display: false, message: "" });
    };

    const openModalMessage = () => {
        setMessage({ display: true, message: "" });
    };

    const sendMessage = () => {
        if(message.message !== "") {
            let facts = new FormData();
            facts.append("text", message.message);
            facts.append("advertid", item.id);
            facts.append("touserid", item.userid);
            facts.append("fromuserid", store.get("user").id);
            facts.append("usertype", "admin");
            facts.append("typechat", 1);

            axios.post("/user/message", facts)
                .then(success => {
                    console.log(success);
                    openModalMessage();
                })
                .catch(error => console.log(error.response));
        }
    };

    const isJson = str => {
        try {
            JSON.parse(str);
        } catch (e) {
            return str;
        }
        return JSON.parse(str)[0];
    }

    let itemPhoto;
    if(item.photo){
        if(item.photo === "Array") itemPhoto = "";
        else itemPhoto = isJson(item.photo)
    }

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="advert">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Просмотр заявки</Typography.Title>
                    </Col>
                    <Col span={12} align="right">
                        <Space>
                            {item.status === 3 && <Button target="_blank" href={"https://nedvizher.ru/user/" + item.userid + "/entity/" + props.advertID}>На сайте</Button>}
                            <Button
                                href={"/chat/" + props.advertID}
                                // onClick={openModalMessage}
                            >
                                Чат
                            </Button>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button type={item.status === 0 ? "primary" : "default"} onClick={closeStatus}>Отклоненные</Button>
                            <Button type={item.status === 1 ? "primary" : "default"} onClick={() => editStatus(1)}>На модерации</Button>
                            <Button type={item.status === 2 ? "primary" : "default"} onClick={() => editStatus(2)}>Архивные</Button>
                            <Button type={item.status === 3 ? "primary" : "default"} onClick={() => editStatus(3)}>Опубликованы</Button>
                        </Space>
                    </Col>
                </Row>
                {types.room.length > 0 && types.type.length > 0 && cities.length > 0 &&
                    <Row gutter={[16, 16]}>
                        <Col span={10}>
                            {types.type.find(el => el.id === item.typeid) &&
                                <p>Тип заявки: {types.type.find(el => el.id === item.typeid).name}</p>
                            }
                            <p>Название: {advertStore.getName(item)}</p>
                            {item.category > 0 &&
                                <p>Категория: {item.category.name}</p>
                            }
                            {/*<p>Тип недвижимости: {types.typeProperty.find(el => el.id === item.typepropertyid).name.name}</p>
                            {/*<p>Кол-во комнат: {item.roomid > 0 ? types.room.find(el => el.id === item.roomid).name : ""}</p>*/}
                            <p>Описание: <p dangerouslySetInnerHTML={{ __html: item.text }} /></p>
                        </Col>
                        <Col span={14}>
                            {item.cityid > 0 &&
                                <p>Город: {cities.find(el => el.id === item.cityid).name}</p>
                            }
                            {item.regionid > 0 ? <p>Район города: {types.region.find(el => el.id === item.regionid).name}</p> : ""}
                            <p>Адрес: {item.address}</p>
                            {/*<p>Радиус поиска: {item.radius}</p>*/}
                            {item.pricefrom === 0 ? (
                                <>
                                    <p>Цена: {item.priceto}р</p>
                                </>
                            ):(
                                <>
                                    <p>Цена: {item.pricefrom}р</p>
                                </>
                            )}
                        </Col>
                    </Row>
                }
                <Row>
                    <Col span={18}>
                        <YMaps>
                            <Map defaultState={{ center: [item.latitude, item.longitude], zoom: 12 }} width={100 + "%"}
                                 height={570}
                            >
                                <Placemark
                                    geometry={[item.latitude, item.longitude]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageOffset: [-15, -28],
                                        draggable: false
                                    }}
                                />
                                <Circle
                                    geometry={[[item.latitude, item.longitude], item.radius * 1000]}
                                    options={{
                                        draggable: false,
                                        fillColor: '#DB709377',
                                        strokeColor: '#990066',
                                        strokeOpacity: 0.8,
                                        strokeWidth: 1,
                                    }}
                                />
                            </Map>
                        </YMaps>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        {itemPhoto !== "" &&
                            <img src={itemPhoto} style={{width: "100%"}} />
                        }
                    </Col>
                </Row>
            </div>
            <Modal title="Укажите причину отклонения" visible={text.display} onOk={() => editStatus(0)} onCancel={closeModal}>
                <Input
                    value={text.text}
                    onChange={e => setText({ ...text, text: e.target.value })}
                />
            </Modal>
            <Modal title="Написать сообщение" visible={message.display} onOk={sendMessage} onCancel={closeModalMessage}>
                <Input.TextArea
                    value={message.message}
                    onChange={e => setMessage({ ...message, message: e.target.value })}
                />
            </Modal>
        </>
    )
};

export default AdvertDetail;