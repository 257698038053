import React, {useState, useEffect} from "react";
import {Button, Col, Row, Table, Space, message, Typography, Modal, Input, Select} from "antd";
import Bread from "../../components/Breadcrumb";
import typeStore from "../../store/typeStore";
import {observer} from "mobx-react-lite";

const breadcrumbs_list = [
    {
        title: "Список городов",
        url: "/city"
    }
];

const Room = observer(() => {

    useEffect(() => {
        typeStore.getTypeProperty();
        typeStore.getRooms();
    }, []);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => typeStore.openModal(record.id)}>Изменить</Button>
                    <Button type="link">Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>Список комнат</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="primary" onClick={() => typeStore.openModal()}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={typeStore.props.room} pagination={false} />

                <Modal
                    title="Редактирование комнаты"
                    visible={typeStore.displayModal}
                    onOk={() => typeStore.sendForm()}
                    onCancel={() => typeStore.closeModal()}>
                    <Input
                        value={typeStore.element.name}
                        placeholder="Название"
                        onChange={e => typeStore.onChange("name", e.target.value)}
                    />
                    <Select
                        placeholder="Выберите тип недвижимости"
                        style={{ width: 100 + "%" }}
                        value={typeStore.element.typePropertyID}
                        onChange={e => typeStore.onChange("typePropertyID", e)}
                    >
                        {typeStore.props.typeProperty.length > 0 && typeStore.props.typeProperty.map(item =>
                            <Select.Option
                                key={item.id}
                                value={item.id}
                            >
                                {item.name.name}
                            </Select.Option>
                        )}
                    </Select>
                </Modal>
            </div>
        </>
    )
});

export default Room;