import slidersController from "./slidersController";
import { useEffect } from "react"
import {Button, Col, Input, Row, Typography, Checkbox, message, Upload} from "antd";
import {observer} from "mobx-react-lite";
import config from "../../config.json";
import {UploadOutlined} from "@ant-design/icons";

const Slider = observer((props) => {
    useEffect(() => {
        if(Number(props.bannerID) > 0) slidersController.getBanner(Number(props.bannerID));
        else slidersController.onChangeBanner("sliderid", Number(props.sliderID));
    }, []);

    let pageTitle = (Number(props.bannerID) > 0) ? "Редактирование баннера" : "Добавить новый баннер"

    const propsImage = {
        maxCount: 1,
        name: 'file',
        action: config.path + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "banners",
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
            }
            if (info.file.status === 'done') {
                console.log(info.file);
                slidersController.onChangeBanner("photo", info.file.response.data.url);

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{pageTitle}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/sliders/" + Number(props.sliderID)}>Вернуться к слайдеру</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => slidersController.setBanner(e)}>
                    <label htmlFor="">Название</label>
                    <Input
                        value={slidersController.banner.name}
                        onChange={(e) => slidersController.onChangeBanner("name", e.target.value)}
                    />

                    <br/><br/>

                    <Checkbox
                        checked={slidersController.banner.active}
                        onChange={(e) => slidersController.onChangeBanner("active", e.target.checked)}
                    >Активен</Checkbox>

                    <br/><br/>

                    <label htmlFor="">Изображение</label>
                    {slidersController.banner.photo !== "" &&
                        <img src={slidersController.banner.photo} alt="" width={150} />
                    }
                    <Upload {...propsImage}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <br/><br/>

                    <label htmlFor="">Ссылка</label>
                    <Input
                        value={slidersController.banner.link}
                        onChange={(e) => slidersController.onChangeBanner("link", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <label htmlFor="">Текст</label>
                    <Input
                        value={slidersController.banner.text}
                        onChange={(e) => slidersController.onChangeBanner("text", e.target.value)}
                    />

                    <br/><br/>

                    <Button type="primary" htmlType="submit">Сохранить</Button>

                </form>
            </div>
        </>
    )

});

export default Slider