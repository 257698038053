import { useState } from "react";
import { Input, Typography, Button } from "antd";
import axios from "axios";

import store from "store";

let sha1 = require('sha1');

export default function SignIn() {

    const [auth, setAuth] = useState({
        email: "",
        password: ""
    });

    const authForm = (e) => {
        e.preventDefault();
        console.log(auth);

        let facts = new FormData();
        facts.append("email", auth.email);
        facts.append("password", sha1(auth.password));
        axios.post("/admin/user", facts)
            .then(success => {
                console.log(success);
                store.set('user', success.data);
                window.location = "/";
            })
            .catch(error => console.log(error.response));
    };

    return(
        <>
            <form onSubmit={authForm}>
                <Typography.Title>Вход</Typography.Title>
                <Input
                    placeholder="Введите e-mail"
                    onChange={(e) => setAuth({ ...auth, email: e.target.value })}
                />
                <Input.Password
                    placeholder="Введите пароль"
                    onChange={(e) => setAuth({ ...auth, password: e.target.value })}
                />
                <Button htmlType="submit" type="primary">Вход</Button>
            </form>
        </>
    )
}