import categoriesController from "./categoriesController";
import { useEffect } from "react"
import {Button, Col, Row, Table, Space, Typography, message} from "antd";
import {observer} from "mobx-react-lite";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import axios from "axios";

const Categories = observer(() => {

    useEffect(() => {
        categoriesController.getAllCategories()
    }, [])

    const changePosition = (id, direction) => {
        axios.get('/object/categoryobject/?id='+ id +'&'+ direction)
            .then(response => {
                categoriesController.list = response.data.subcategory[0];
            })
            .catch(error => {
                message.error(error);
            });
    }

    const columns = [
        {
            title: "Сортировка",
            dataIndex: "sort",
            key: "sort",
            width: "60px",
            render: (text, record) => (
                <Space size="middle" direction="vertical">
                    <Button onClick={e => changePosition(record.id, "moveUp")}><UpOutlined /></Button>
                    <Button onClick={e => changePosition(record.id, "moveDown")}><DownOutlined /></Button>
                </Space>
            ),
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/categories/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/categories/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => categoriesController.deleteCategory(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Список категорий</Typography.Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/categories/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={categoriesController.list} />

            </div>
        </>
    )
})

export default Categories

