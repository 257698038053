import React, {useEffect, useState} from "react";
import {useRoutes} from 'hookrouter';
import Routes from "./Routes";
import axios from 'axios'
import store from "store"
import config from "./config.json"

import Bread from './components/Breadcrumb';

import {Layout, Menu, Row, Col} from 'antd';
import {
    PieChartOutlined,
    BankOutlined,
    TeamOutlined,
    UserOutlined,
    PicRightOutlined,
    LogoutOutlined,
    SnippetsOutlined,
    WechatOutlined
} from '@ant-design/icons';
import advertStore from "./store/advertStore";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

axios.defaults.baseURL = config.path;
axios.defaults.headers.common['Type-Device'] = config.typeDevice;


function App() {
    let routeResult = useRoutes(Routes);

    useEffect(() => {
        advertStore.getFiltersNames(0);
    }, [])

    const logout = () => {
        store.clearAll();
        window.location = "/login";
    };

    let path = window.location.href.split("/");

    return (
        <>
            {store.get("user") && store.get('user').token !== "" &&
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible>
                    <div className="logo" >
                        <p>Недвижер</p>
                        <span>Admin</span>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['dashboard']} mode="inline" selectedKeys={path[3]}>
                        <Menu.Item key="" icon={<PieChartOutlined />}>
                            <a href="/">Рабочий стол</a>
                        </Menu.Item>
                        <Menu.Item key="advert" icon={<PicRightOutlined />}>
                            <a href="/advert">Объявления</a>
                        </Menu.Item>
                        {store.get("user").permission !== "moderator" && store.get("user").permission !== "manager" &&
                            <Menu.Item key="users" icon={<UserOutlined />}>
                                <a href="/users">Пользователи</a>
                            </Menu.Item>
                        }
                        {store.get("user").permission !== "manager" &&
                        <Menu.Item key="complaints" icon={<UserOutlined />}>
                            <a href="/complaints">Жалобы</a>
                        </Menu.Item>
                        }
                        <Menu.Item key="advert"><a href="/props/advert">Типы объявлений</a></Menu.Item>
                        <Menu.Item key="categories" icon={<BankOutlined />}>
                            <a href="/categories">Категории</a>
                        </Menu.Item>
                        <Menu.Item key="filters" icon={<BankOutlined />}>
                            <a href="/filters">Фильтры</a>
                        </Menu.Item>
                            {/*<Menu.Item key="type"><a href="/props/type">Типы недвижимостей</a></Menu.Item>*/}
                            {/*<Menu.Item key="room"><a href="/props/room">Комнаты</a></Menu.Item>*/}
                        <Menu.Item key="sliders" icon={<BankOutlined />}>
                            <a href="/sliders">Слайдеры</a>
                        </Menu.Item>
                        <Menu.Item key="city" icon={<BankOutlined />}>
                            <a href="/city">Города</a>
                        </Menu.Item>
                        {/*<Menu.Item key="metro" icon={<BankOutlined />}>*/}
                        {/*    <a href="/metro">Станции метро</a>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="faq" icon={<BankOutlined />}>*/}
                        {/*    <a href="/faq">Вопросы-ответы</a>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item key="admin" icon={<TeamOutlined />}>
                            <a href="/admin">Администраторы</a>
                        </Menu.Item>
                        {/*<Menu.Item key="documents" icon={<SnippetsOutlined />}>*/}
                        {/*    <a href="/documents">Документы</a>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="reviews" icon={<WechatOutlined />}>*/}
                        {/*    <a href="/reviews">Отзывы</a>*/}
                        {/*</Menu.Item>*/}
                        {store.get("user").permission !== "moderator" && store.get("user").permission !== "manager" &&
                            <Menu.Item key="news" icon={<PicRightOutlined/>}>
                                <a href="/news">Новости</a>
                            </Menu.Item>
                        }
                        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>Выйти</Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '0 16px' }}>
                        {routeResult}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Development: Apps Group</Footer>
                </Layout>
            </Layout>
            }
            {!store.get("user") &&
                <Row justify="center" align="middle" style={{height: 100 + "vh"}}>
                    <Col span={7}>
                        {routeResult}
                    </Col>
                </Row>
            }
        </>
    );
}

export default App;
