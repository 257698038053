import {useState, useEffect} from 'react';
import { Breadcrumb } from 'antd';

export default function Bread(props) {

    const [items, setItems] = useState([
        {
            title: 'Главная',
            href: "/"
        }
    ]);

    useEffect(() => {
        if(props.items && props.items.length > 0) {
            setItems(props.items);
        }
    }, [props]);

    const GetBreadcrumds = () => {
        let itemsList = items.map((item) => {
            return(
                <Breadcrumb.Item key={"breadcrumb" + item.title}>
                    <a href={item.url}>{item.title}</a>
                </Breadcrumb.Item>
            )
        });

        return(
            <Breadcrumb style={{ margin: '16px 15px' }}>
                <Breadcrumb.Item key={"breadcrumbHome"}>
                    <a href="/">Главная</a>
                </Breadcrumb.Item>
                {itemsList}
            </Breadcrumb>
        )
    };

    return(
        <GetBreadcrumds />
    )
}