import React, {useState, useEffect} from 'react';
import {Button, Col, message, Row, Space, Table, Typography} from "antd";
import axios from "axios";
import Bread from "../../components/Breadcrumb";

const breadcrumbs_list = [
    {
        title: "Новости",
        url: "/news"
    }
];

export default function NewsList() {

    const [update, setUpdate] = useState(0);
    const [news, setNews] = useState([]);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/news/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/news/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => deleteNews(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];



    useEffect(() => {
        axios.get('/news/news')
            .then(response => {
                console.log(response);
                setNews(response.data);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }, [update]);

    const deleteNews = (id) => {
        if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
            axios.delete('/news/news/' + id)
                .then(response => {
                    console.log(response);
                    setUpdate(update + 1);
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Список новостей</Typography.Title>
                    </Col>
                    <Col span={12} align="right">
                        <Button type="primary" href={"/news/new"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={news} />

            </div>
        </>
    )
}