import {makeAutoObservable} from "mobx";
import axios from "../../store/axios";
import { message } from "antd";

class documentController {
    constructor() {
        makeAutoObservable(this)
    }

    documents = {
        data: [],
        page: 1,
        all: 1
    }

    document = {}

    setLimit(page) {
        console.log(page)
        this.documents.page = page
        this.getDocuments()
    }

    getDocuments() {
        axios.get("/document/document?page=" + this.documents.page)
            .then(success => {
                this.documents.data = success.data[0]
                this.documents.all = success.data[1]
            })
            .catch(error => console.log(error))
    }

    getDocumentByID(documentID) {
        axios.get("/document/document/" + documentID)
            .then(success => {
                this.document = success.data
            })
            .catch(error => console.log(error))
    }

    setDocument(name, value) {
        this.document[name] = value
    }

    deleteDocument(documentID) {
        axios.delete("/document/document/" + documentID)
            .then(success => {
                this.getDocuments()
            })
            .catch(error => console.log(error))
    }

    postDocument(e) {
        e.preventDefault()

        if(this.document.name && this.document.name !== "" && this.document.link && this.document.link !== "") {
            let facts = new FormData()
            facts.append("name", this.document.name)
            facts.append("link", this.document.link)

            let url = "/document/document/"
            if(this.document.id && Number(this.document.id) > 0) {
                url = url + this.document.id
            }

            axios.post(url, facts)
                .then(success => {
                    document.location.href="/documents";
                })
                .catch(error => console.log(error))
        } else {
            message.error("Заполнены не все обязательные поля");
        }
    }

}

export default new documentController()