import React, {useState, useEffect} from 'react';
import {Button, Col, Row, Table, Space, message, Typography} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";

const breadcrumbs_list = [
    {
        title: "Список городов",
        url: "/city"
    }
];

const { Title } = Typography;

export default function CityList() {
    const [update, setUpdate] = useState(0);
    const [city, setCity] = useState([]);

    useEffect(() => {
        axios.get('/user/city')
            .then(response => {
                console.log(response);
                setCity(response.data);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }, [update]);

    const deleteCity = (id) => {
        if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
            axios.delete('/user/city/' + id)
                .then(response => {
                    console.log(response);
                    setUpdate(update + 1);
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    };

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/city/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/city/" + record.id + "/regions"}>Районы</a>
                    <a href={"/city/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => deleteCity(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Title>Список городов</Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/city/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={city} />

            </div>
        </>
    )
}