import filtersController from "./filtersController";
import {useEffect, useRef} from "react"
import {Button, Checkbox, Col, Input, message, Row, Select, Typography, Upload} from "antd";
import {observer} from "mobx-react-lite";
import cursor from "quill/blots/cursor";

const Filter = observer((props) => {

    useEffect(() => {
        if((Number(props.filterID) > 0)) filtersController.getFilter(Number(props.categoryID), Number(props.filterID))
        if((Number(props.categoryID) !== 0)) filtersController.onChangeFilter("filtercategoryid", Number(props.categoryID));
    }, [])

    let pageTitle = (Number(props.filterID) > 0) ? "Редактирование фильтра" : "Добавить новый фильтр";

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{pageTitle}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/filters/" + Number(props.categoryID)}>Вернуться к категории</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => filtersController.setFilter(e)}>
                    <label htmlFor="">Название</label>
                    <Input
                        autoFocus
                        value={filtersController.filter.label}
                        onChange={(e) => filtersController.onChangeFilter("label", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )
})

export default Filter