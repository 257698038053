import {observer} from "mobx-react-lite";
import Bread from "../../components/Breadcrumb";
import {Button, Col, Row, Space, Table, Typography, Pagination} from "antd";
import React, { useEffect } from "react";
import doc from "./document.controller"

const Documents = observer(() => {

    const breadcrumbs_list = [
        {
            title: "Список документов",
            url: "/documents"
        }
    ];

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/documents/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => doc.deleteDocument(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        doc.getDocuments()
    }, [])

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Список документов</Typography.Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/documents/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={doc.documents.data} pagination={false} />

                <Pagination current={doc.documents.page} total={doc.documents.all} showSizeChanger={false} onChange={e => doc.setLimit(e)} />
            </div>
        </>
    )
})

export default Documents