import {useState, useEffect} from 'react';
import Bread from "../../components/Breadcrumb";
import {Button, Col, Row, Space, Table, Typography, Select, InputNumber, Pagination, Input, Modal, Tag} from "antd";
import axios from "axios";
import advertStore from "../../store/advertStore";
import {observer} from "mobx-react-lite";

const breadcrumbs_list = [
    {
        title: "Список заявок",
        url: "/advert"
    }
];

const { Title } = Typography;

const AdvertList = observer(() => {

    useEffect(() => {
        advertStore.fetchAll();
        advertStore.fetchTypes();
        advertStore.getAdminUsers();
    }, []);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                if(record.filteradvert){
                    record.filteradvert.map(c => {
                        advertStore.getFilterCategory(c.filtercategoryid);
                    });
                }
                return(
                    <>
                        {advertStore.getName(record)}
                        {/*{advertStore.adminList.find(el => el.id === record.userid) && <Tag color="#108ee9">Ваша заявка</Tag>}*/}

                    </>
                )
            },
        },
        {
            title: 'Комментарий',
            dataIndex: 'text',
            key: 'text',
            render: (text, row) => <p dangerouslySetInnerHTML={{ __html: text }} />
        },
        {
            title: 'Кол-во предложений',
            dataIndex: 'message',
            key: 'message',
            render: (text, row) => <a href={"/chat/" + row.id}>Предложений: {text}</a>
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {record.status === 1 &&
                        <>
                            <Button onClick={() => advertStore.acceptAdvert(record.id)}>Принять</Button>
                            <Button onClick={() => advertStore.openModal(record.id)}>Отклонить</Button>
                        </>
                    }
                    <a href={"/advert/" + record.id} target="_blank">Просмотр</a>
                </Space>
            ),
        },
    ];

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="advert">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Title>Список заявок</Title>
                    </Col>
                    <Col span={12} className="right">

                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="bottom">
                    <Col span={4}>
                        <label htmlFor="key1">Выберите город</label>
                        <Select
                            showSearch
                            id="cityID"
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={advertStore.filter.city > 0 ? advertStore.filter.city : ""}
                            onChange={(e) => advertStore.changeFilter("city", e)}
                        >
                            {advertStore.cities.length > 0 && advertStore.cities.map(item =>
                                advertStore.searchCityName !== ""
                                    ? item.name.indexOf(advertStore.searchCityName) > -1 && <Select.Option value={item.id}>{item.name}</Select.Option>
                                    : <Select.Option value={item.id}>{item.name}</Select.Option>
                            )}
                        </Select>
                    </Col>
                    {/*<Col span={5}>*/}
                    {/*    <label htmlFor="key1">Выберите район</label>*/}
                    {/*    <Select*/}
                    {/*        id="key1"*/}
                    {/*        style={{ width: '100%' }}*/}
                    {/*        value={advertStore.filter.region > 0 ? advertStore.filter.region : null}*/}
                    {/*        onChange={(e) => advertStore.changeFilter("region", e)}*/}
                    {/*        disabled={advertStore.filter.city > 0 ? "" : "disabled"}*/}
                    {/*    >*/}
                    {/*        <Select.Option value={null}>Все районы</Select.Option>*/}
                    {/*        {advertStore.types.region.length > 0 && advertStore.types.region.filter(el => el.cityid === advertStore.filter.city).map(item => (*/}
                    {/*            <Select.Option value={item.id}>{item.name}</Select.Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Col>*/}
                    <Col span={5}>
                        <label htmlFor="key1">Тип заявки</label>
                        <Select
                            id="key1"
                            style={{ width: '100%' }}
                            value={advertStore.filter.type_advert > 0 ? advertStore.filter.type_advert : ""}
                            onChange={(e) => advertStore.changeFilter("type_advert", e)}
                        >
                            {advertStore.types.type.length > 0 && advertStore.types.type.map(item => (
                                <Select.Option value={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    {/*<Col span={5}>*/}
                    {/*    <label htmlFor="key1">Тип недвижимости</label>*/}
                    {/*    <Select*/}
                    {/*        id="key1"*/}
                    {/*        style={{ width: '100%' }}*/}
                    {/*        value={advertStore.filter.type_object > 0 ? advertStore.filter.type_object : ""}*/}
                    {/*        onChange={(e) => advertStore.changeFilter("type_object", e)}*/}
                    {/*    >*/}
                    {/*        {advertStore.types.typeProperty.length > 0 && advertStore.types.typeProperty.map(item => (*/}
                    {/*            <Select.Option value={item.id}>{item.name.name}</Select.Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Col>*/}
                    {/*<Col span={5}>*/}
                    {/*    <label htmlFor="key1">*/}
                    {/*        {advertStore.filter.type_object > 0 ? advertStore.types.typeProperty.find(el => el.id === advertStore.filter.type_object).name.filter : ""}*/}
                    {/*    </label>*/}
                    {/*    {advertStore.filter.type_object > 0 && advertStore.types.typeProperty.find(el => el.id === advertStore.filter.type_object).type === 1 &&*/}
                    {/*        <Select*/}
                    {/*            id="key1"*/}
                    {/*            mode="multiple"*/}
                    {/*            style={{width: '100%'}}*/}
                    {/*            value={advertStore.filter.room.length > 0 ? advertStore.filter.room : []}*/}
                    {/*            disabled={advertStore.filter.type_object > 0 ? "" : "disabled"}*/}
                    {/*            onChange={(e) => advertStore.changeFilter("room", e)}*/}
                    {/*        >*/}
                    {/*            {advertStore.types.room.length > 0 && advertStore.types.room.filter(el => el.typepropertyid === advertStore.filter.type_object).map(item => (*/}
                    {/*                <Select.Option value={item.id}>{item.name}</Select.Option>*/}
                    {/*            ))}*/}
                    {/*        </Select>*/}
                    {/*    }*/}
                    {/*    {advertStore.filter.type_object > 0 && advertStore.types.typeProperty.find(el => el.id === advertStore.filter.type_object).type === 2 &&*/}
                    {/*        <Space>*/}
                    {/*            <InputNumber*/}
                    {/*                placeholder="от"*/}
                    {/*                onChange={(e) => advertStore.changeFilter("price_from", e)}*/}
                    {/*            />*/}

                    {/*            <InputNumber*/}
                    {/*                placeholder="до"*/}
                    {/*                onChange={(e) => advertStore.changeFilter("price_to", e)}*/}
                    {/*            />*/}
                    {/*        </Space>*/}
                    {/*    }*/}
                    {/*    {advertStore.filter.type_object > 0 && advertStore.types.typeProperty.find(el => el.id === advertStore.filter.type_object).type === 3 &&*/}
                    {/*        <Input*/}
                    {/*            placeholder="Введите ориентир"*/}
                    {/*            onChange={(e) => advertStore.changeFilter("stringval", e.target.value)}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*</Col>*/}
                    <Col span={4}>
                        <label htmlFor="key1">Статус</label>
                        <Select
                            id="status"
                            style={{ width: '100%' }}
                            onChange={(e) => advertStore.changeFilter("status", e)}
                            value={advertStore.filter.status !== null ? advertStore.filter.status : ""}
                        >
                            <Select.Option key={"status0"} value={0}>Отклоненные</Select.Option>
                            <Select.Option key={"status1"} value={1}>На модерации</Select.Option>
                            <Select.Option key={"status2"} value={2}>Архивные</Select.Option>
                            <Select.Option key={"status3"} value={3}>Опубликованы</Select.Option>
                        </Select>
                    </Col>
                    <Col span={5}>
                        <label htmlFor="key1">Стоимость от</label>
                        <InputNumber
                            placeholder="от"
                            style={{ width: '100%' }}
                            value={advertStore.filter.price_from >= 0 ? advertStore.filter.price_from : ""}
                            onChange={(e) => advertStore.changeFilter("price_from", e)}
                        />
                    </Col>
                    <Col span={5}>
                        <label htmlFor="key1">Стоимость до</label>
                        <InputNumber
                            placeholder="до"
                            style={{ width: '100%' }}
                            value={advertStore.filter.price_to > 0 ? advertStore.filter.price_to : ""}
                            onChange={(e) => advertStore.changeFilter("price_to", e)}
                        />
                    </Col>
                    <Col span={5}>
                        <Button type="primary" onClick={() => advertStore.fetchAll()} block>Показать заявки</Button>
                    </Col>
                    <Col span={5}>
                        <Button onClick={() => advertStore.clearFilter()} block>Показать все</Button>
                    </Col>
                </Row>

                {advertStore.adverts.length > 0 && advertStore.types.room.length > 0 &&
                    <>
                        <Table columns={columns} dataSource={advertStore.adverts} pagination={false} />
                        <br/>
                        <Pagination
                            current={advertStore.page}
                            total={advertStore.count}
                            onChange={(e) => advertStore.setPage(e)}
                            onShowSizeChange={(e, size) => advertStore.setLimit(size)}
                        />
                    </>
                }

                <Modal
                    title="Укажите причину отклонения"
                    visible={advertStore.modal.display}
                    onOk={() => advertStore.sendModal()}
                    onCancel={() => advertStore.closeModal()}
                >
                    <Input.TextArea
                        value={advertStore.modal.text}
                        onChange={e => advertStore.setErrorText(e.target.value)}
                    />
                </Modal>
            </div>
        </>
    )
});

export default AdvertList;