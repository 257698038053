import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";
import {message} from "antd";

class filtersController {
    list = [];
    filters = [];

    element = {
        id: null,
        name: "",
        value: "",
        type: "0",
        nameobject: ""
    }

    filter = {
        id: null,
        label: "",
        filtercategoryid: "",
        value: 0
    }

    constructor() {
        makeAutoObservable(this);
    }

    onChange(item, value) {
        this.element[item] = value
        //console.log(this.element[item])
    }

    onChangeFilter(item, value) {
        this.filter[item] = value
        //console.log(this.filter[item])
    }

    getFiltersCategories() {
        axios.get("/object/filtercategory/0")
            .then(success => {
                this.list = success.data
                console.log(success)
            });
    }

    getFilterCategory(id) {
        axios.get("/object/filtercategory/" + id)
            .then(success => {
                this.element = success.data;
                this.element.value = success.data.namevalue;
                console.log(success)
            });
        axios.get("/object/filtervalue/" + id)
            .then(success => {
                this.filters = success.data[0];
                console.log(success)
            });
    }

    getFilter(categoryID, filterID) {
        axios.get("/object/filtervalue/" + categoryID)
            .then(success => {
                console.log(success.data);
                this.filter = success.data[0].find(el => el.id === filterID);
            });
    }

    setFilterCategory(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("name", this.element.name)
        facts.append("namevalue", this.element.value)
        facts.append("type", this.element.type)
        facts.append("nameobject", Number(this.element.nameobject))

        if(this.element.type === "0") return message.error("Не указан тип фильтра");

        let url;
        if(this.element.id){
            url = "/object/filtercategory/" + this.element.id
        } else {
            url = "/object/filtercategory/";
        }

        axios.post(url, facts)
            .then(success => {
                window.location.href = "/filters";
            });
    }

    setFilter(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("label", this.filter.label)
        facts.append("filtercategoryid", this.filter.filtercategoryid)
        facts.append("value", this.filter.label)


        let url;
        if(this.filter.id){
            url = "/object/filtervalue/" + this.filter.id
        } else {
            url = "/object/filtervalue/";
        }

        axios.post(url, facts)
            .then(success => {
                window.location.href = "/filters/" + this.filter.filtercategoryid;
            }).catch(error => console.log(error.response));
    }

    deleteFilter(id){
        axios.delete("/object/filtercategory/" + id)
            .then(success => {
                window.location.href = "/filters";
            })
    }

    deleteFilterValue(id){
        axios.delete("/object/filtervalue/" + id)
            .then(success => {
                window.location.reload();
            })
    }
}

export default new filtersController();