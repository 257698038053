import faqController from "./faqController";
import { useEffect } from "react"
import {Button, Col, Input, Row, Typography} from "antd";
import {observer} from "mobx-react-lite";

const FaqDetail = observer((props) => {

    useEffect(() => {
        faqController.getFaqByID(props.faqID)
    }, [])

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{faqController.element.question}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/faq"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => faqController.setFaq(e)}>
                    <label htmlFor="">Вопрос</label>
                    <Input
                        value={faqController.element.question}
                        onChange={(e) => faqController.onChangeText("question", e.target.value)}
                        required
                    />

                    <label htmlFor="">Ответ</label>
                    <Input
                        value={faqController.element.answer}
                        onChange={(e) => faqController.onChangeText("answer", e.target.value)}
                        required
                    />


                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )
})

export default FaqDetail