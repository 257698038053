import {observer} from "mobx-react-lite";
import { useEffect, useState } from "react"
import { Row, Col, Input, Comment, Button } from "antd";
import chatStore from "../store/chatStore";
import store from "store";

const Chat = observer((props) => {

    const [type, setType] = useState("");

    useEffect(() => {
        chatStore.getAdvert(props.advertID);
        chatStore.getChatMessages(props.advertID);
    }, []);

    useEffect(() => {
        if(type === "link") {
            chatStore.setMessage("");
        } else if(type === "message") {
            chatStore.setLink("");
        }
    }, [type]);

    return(
        <>
            <div className="chat-list">
                <div className="top" id="messages-block">
                    {chatStore.list.length > 0 && chatStore.list.map(item => {

                        return(
                            <Comment
                                author={item.fromuserid !== chatStore.item.userid ? "Админ" + chatStore.item.admin.firstname + " (" + chatStore.item.admin.email + ")" : "Автор"}
                                content={
                                    <>
                                        {item.type === 0 ? <a href={item.text}>{item.text}</a> : <p>{item.text}</p>}
                                        <br/>
                                        {item.status === 2 && "Пользователь оценил данную ссылку положительно"}
                                        {item.status === 1 && "Жалоба: " + item.comment}
                                    </>
                                }
                            />
                        )
                    })}
                </div>
                <div className="bottom">

                </div>
            </div>
            <Row>
                <Col span={24}>

                </Col>
                {store.get("user").permission !== "moderator" &&
                <Col span={24}>
                    <Button onClick={() => setType("message")}>Отправить сообщение</Button>
                    <Button onClick={() => setType("link")}>Отправить ссылку</Button>
                    {type === "message" &&
                    <Input.TextArea
                        value={chatStore.message}
                        onChange={(e) => chatStore.setMessage(e.target.value)}
                        placeholder="Введите сообщение"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    }
                    {type === "link" &&
                    <Input.TextArea
                        value={chatStore.link}
                        onChange={(e) => chatStore.setLink(e.target.value)}
                        placeholder="Укажите ссылку"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    }

                    <Button type="primary" onClick={() => chatStore.sendMessage(props.chatID, props.advertID)}>Отправить</Button>
                </Col>
                }
            </Row>
        </>
    )
});

export default Chat;
