import filtersController from "./filtersController";
import { useEffect } from "react"
import {Button, Col, Input, Checkbox, Row, Select, Space, Table, Typography} from "antd";
import {observer} from "mobx-react-lite";
import categoriesController from "../categories/categoriesController";

const FiltersCategory = observer((props) => {
    useEffect(() => {
        categoriesController.getAllCategories();
        if(Number(props.filterID) > 0) filtersController.getFilterCategory(Number(props.filterID));
    }, []);

    let pageTitle = (Number(props.filterID) > 0) ? "Редактирование категории фильтров" : "Добавить новую категорию фильтров"

    const columns = [
        {
            title: 'Название',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => <a href={"/filter/" + Number(props.filterID) + "/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/filter/" + Number(props.filterID) + "/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => filtersController.deleteFilterValue(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{pageTitle}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/filters"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => filtersController.setFilterCategory(e)}>
                    <label htmlFor="">Название</label>
                    <Input
                        value={filtersController.element.name}
                        onChange={(e) => filtersController.onChange("name", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <label htmlFor="">На латиннице</label>
                    <Input
                        value={filtersController.element.value}
                        onChange={(e) => filtersController.onChange("value", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <label htmlFor="">Тип фильтра</label>
                    <Select value={filtersController.element.type} style={{ width: 200 }} onChange={(e) => filtersController.onChange("type", e)}>
                        <Select.Option value="0">Выберите тип</Select.Option>
                        <Select.Option value="radio">Селект</Select.Option>
                        <Select.Option value="checked">Кнопки</Select.Option>
                        {/*<Select.Option value="range">Диапазон</Select.Option>*/}
                    </Select>

                    <br/><br/>

                    <Checkbox
                        checked={filtersController.element.nameobject}
                        onChange={(e) => filtersController.onChange("nameobject", e.target.checked)}
                    >Участие категории фильтра в имени объявления</Checkbox>

                    <br/><br/>

                    {Number(props.filterID) > 0 && filtersController.element.type !== "range" &&
                        <Row justify="space-around" align="middle">
                            <Col span={12}>
                                <Typography.Title level={3}>Фильтры категории</Typography.Title>
                            </Col>
                            <Col span={12} align={'right'}>
                                <Button type="primary" href={"/filter/" + Number(props.filterID) + "/0"}>Создать</Button>
                            </Col>
                            <Col span={24}>
                                <Table columns={columns} dataSource={filtersController.filters} />
                            </Col>
                        </Row>
                    }

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )

});

export default FiltersCategory