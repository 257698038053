import categoriesController from "./categoriesController";
import { useEffect } from "react"
import {Button, Checkbox, Col, Input, message, Row, Select, Typography, Upload} from "antd";
import {observer} from "mobx-react-lite";
import filtersController from "../filters/filtersController";
import config from "../../config.json";
import {UploadOutlined} from "@ant-design/icons";

const Category = observer((props) => {

    useEffect(() => {
        if(Number(props.categoryID) > 0) categoriesController.getCategoryByID(Number(props.categoryID))
        categoriesController.getParentCategories();
        filtersController.getFiltersCategories();
    }, [])

    let pageTitle = (Number(props.categoryID) > 0) ? "Редактирование категории" : "Добавить новую категорию"

    const propsImage = {
        maxCount: 1,
        name: 'file',
        action: config.path + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "categories",
        },
        onChange(info) {
            console.log(info.file)
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
            }
            if (info.file.status === 'done') {
                console.log(info.file);
                categoriesController.onChangeText("photo", info.file.response.data.url);

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{pageTitle}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/categories"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => categoriesController.setCategory(e)}>
                    <label htmlFor="">Название</label>
                    <Input
                        value={categoriesController.element.name}
                        onChange={(e) => categoriesController.onChangeText("name", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <label htmlFor="">Описание</label>
                    <Input
                        value={categoriesController.element.text}
                        onChange={(e) => categoriesController.onChangeText("text", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <label htmlFor="">Синонимы</label>
                    <Input
                        value={categoriesController.element.search}
                        onChange={(e) => categoriesController.onChangeText("search", e.target.value)}
                        required
                    />

                    <br/><br/>

                    {categoriesController.element.subcategory[0].length === 0 &&
                    <>
                        <label htmlFor="">Родительская категория</label>
                        <Select value={categoriesController.element.parentid} style={{ width: 200 }} onChange={(e) => categoriesController.onChangeText("parentid", e)}>
                            <Select.Option value="0">Нет родительской категории</Select.Option>
                            {categoriesController.list.map(e => {
                                return <Select.Option value={e.id}>{e.name}</Select.Option>
                            })}
                        </Select>

                        <br/><br/>
                    </>
                    }

                    <label htmlFor="">Категории фильтров</label>
                    <Select
                        placeholder="Выберите одну или несколько"
                        value={categoriesController.filters}
                        style={{ width: 340 }}
                        onChange={(e) => categoriesController.onChangeFilters(e)}
                        mode="multiple"
                        allowClear
                    >
                        {filtersController.list.map(e => {
                            return <Select.Option value={e.id}>{e.name}</Select.Option>
                        })}
                    </Select>

                    <br/><br/>

                    <label htmlFor="">Изображение</label>

                    {categoriesController.element.photo !== "" &&
                        <img src={categoriesController.element.photo} alt="" width={150} />
                    }

                    <Upload {...propsImage}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>

                    <br/><br/>

                    <Checkbox
                        checked={categoriesController.element.nameobject}
                        onChange={(e) => categoriesController.onChangeText("nameobject", e.target.checked)}
                    >Возможность указывать произвольное имя объявления</Checkbox>

                    <br/><br/>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )
})

export default Category