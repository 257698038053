import React, {useState, useEffect} from 'react';
import {Button, Col, message, Row, Space, Table, Typography} from "antd";
import axios from "axios";
import Bread from "../../components/Breadcrumb";

const { Title } = Typography;

const breadcrumbs_list = [
    {
        title: "Список пользователей",
        url: "/users"
    }
];

export default function UsersList() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('/user/userlist')
            .then(success => {
                console.log(success.data);
                setUsers(success.data);
            })
            .catch(error => {
                    message.error(error.response.data.message);
                }
            )
    }, []);

    const columns = [
        {
            title: 'Логин/Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            align: 'right',
        },
    ];

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={24}>
                        <Title>Список пользователей</Title>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={users} />

            </div>
        </>
    )
}