import React, {useState, useEffect} from 'react';
import {Button, Col, Row, Table, Space, message, Typography, Input, Modal} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";

const breadcrumbs_list = [
    {
        title: "Список районов",
        url: "/region"
    }
];

const { Title } = Typography;

export default function RegionList(props) {
    const [update, setUpdate] = useState(0);
    const [region, setRegion] = useState([]);

    const [item, setItem] = useState({
        display: false,
        id: 0,
        name: "",
        cityID: 0
    });

    useEffect(() => {
        axios.get('/user/region/?cityid=' + props.cityID)
            .then(response => {
                console.log(response);
                setRegion(response.data);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }, [update]);

    const deleteRegion = (id) => {
        if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
            axios.delete('/user/region/' + id)
                .then(response => {
                    console.log(response);
                    setUpdate(update + 1);
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    };

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => text,
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/region/" + record.id}>{text}</a>,
        },
        {
            title: 'id  города',
            dataIndex: 'cityid',
            key: 'cityid',
            render: (text, record) => text,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => openModal(record.id)}>Изменить</Button>
                    <Button type="link" onClick={() => deleteRegion(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    const openModal = (id=0) => {
        if(id > 0) {
            axios.get('/user/region/' + id)
                .then(response => {
                    setItem({
                        display: true,
                        id: response.data.id,
                        name: response.data.name,
                        cityid: response.data.cityid
                    });
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        } else {
            setItem({
                display: true,
                id: 0,
                name: "",
                cityid: props.cityID
            });
        }
    };

    const closeModal = () => {
        setItem({
            display: false,
            id: 0,
            name: "",
            cityID: 0
        });
    };

    const sendForm = () => {
        let facts = new FormData();
        facts.append('name', item.name);
        facts.append('cityid', item.cityid);

        let url = "/user/region/";
        if(Number(item.id) > 0) {
            url = "/user/region/" + item.id;
        }

        axios.post(url, facts)
            .then(response => {
                setUpdate(update + 1);
                closeModal();
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Title>Список регионов</Title>
                    </Col>
                    <Col span={12} align="right">
                        <Button type="primary" onClick={openModal}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={region} />

            </div>
            <Modal title="Редактирование района города" visible={item.display} onOk={sendForm} onCancel={closeModal}>
                <Input
                    value={item.name}
                    placeholder="Введите название района"
                    onChange={e => setItem({ ...item, name: e.target.value })}
                />
            </Modal>
        </>
    )
}