import { makeAutoObservable, makeObservable } from "mobx";
import axios from "./axios";
import store from "store";

class ChatStore {

    message = "";
    list = [];
    item = {};
    link = "";
    userID = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setMessage(value) {
        this.message = value;
    }

    setLink(value) {
        this.link = value;
    }

    setChatActive(chatID) {
        this.active = chatID;
    }

    getAdvert(advertID) {
        axios.get("/object/object/" + advertID)
            .then(success => {
                console.log(success);
                this.item = success.data;
            })
            .catch(error => console.log(error.response));
    }

    getChatMessages(advertID) {
        axios.get("/user/chatadmin/" + advertID)
            .then(success => {
                console.log(success);
                if(Number(success.data[0].chatid) > 0) {
                    axios.get("/user/message/" + Number(success.data[0].chatid))
                        .then(messages => {
                            console.log(messages);
                            this.list = messages.data[0];
                            document.getElementById("messages-block").scrollBy(0, document.getElementById("messages-block").scrollHeight);
                        })
                        .catch(error => console.log(error.response));
                }
            })
            .catch(error => console.log(error.response));
    }

    sendMessage(chatID="", advertID) {
        if(this.message !== "" || this.link !== "") {
            let facts = new FormData();

            if(this.link !== "") {
                facts.append("text", this.link);
                facts.append("type", "link");
            } else {
                facts.append("text", this.message);
            }

            facts.append("advertid", this.item.id);
            facts.append("touserid", this.item.userid);
            facts.append("fromuserid", 0);
            facts.append("admin", store.get("user").id);
            facts.append("usertype", "admin");
            facts.append("typechat", 1);

            axios.post("/user/message", facts)
                .then(success => {
                    console.log(success);
                    this.getChatMessages(advertID);
                    this.clearForm();
                    document.getElementById("messages-block").scrollBy(0, document.getElementById("messages-block").scrollHeight);
                })
                .catch(error => console.log(error.response));
        }
    }

    clearForm() {
        this.message = "";
        this.link = "";
    }
}

export default new ChatStore();