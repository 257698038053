import {observer} from "mobx-react-lite";
import Bread from "../../components/Breadcrumb";
import React, { useEffect } from "react";
import doc from "./document.controller"
import {Button, Col, Input, message, Row, Typography, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import config from "../../config.json";

const Document = observer(({ documentID=0 }) => {

    const breadcrumbs_list = [
        {
            title: "Список документов",
            url: "/documents"
        },
        {
            title: "Редактирование документа",
            url: "#"
        }
    ];

    const propsImage = {
        name: 'file',
        action: config.path + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "document",
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
            }
            if (info.file.status === 'done') {
                console.log(info.file);
                doc.setDocument("link", info.file.response.data.url)

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    if(documentID > 0) {
        useEffect(() => {
            doc.getDocumentByID(documentID)
        }, [])
    }

    return(
        <>
            <Bread items={breadcrumbs_list} />

            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>Редактирование документа</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/documents"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={e => doc.postDocument(e)}>
                    <label htmlFor="">Наименование</label>
                    <Input
                        value={doc.document.name}
                        onChange={(e) => doc.setDocument("name", e.target.value)}
                        required
                    />

                    {doc.document.link && doc.document.link !== "" &&
                        <a href={ doc.document.link } target="_blank">Загруженный документ</a>
                    }

                    <Upload {...propsImage}>
                        <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                    </Upload>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>

            </div>
        </>
    )
})

export default Document