import {observer} from "mobx-react-lite";
import {Col, Row, Table, Typography} from "antd";
import complaintsStore from "../../store/complaintsStore";
import { useEffect } from "react"

const columns = [
    {
        title: 'ID объявления',
        dataIndex: 'advertid',
        key: 'advertid',
    },
    {
        title: 'От кого',
        render: () => (
            <>Администратор</>
        )
    },
    {
        title: 'Кому',
        render: (object) => (
            <>{object.advertid}</>
        )
    },
    {
        title: 'Ссылка на объявление',
        dataIndex: 'advertid',
        key: 'advertid',
        render: (text) => (
            <a href={"/advert/" + text}>Ссылка</a>
        )
    },
    {
        title: 'Адресс ссылки',
        dataIndex: 'text',
        key: 'text',
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment'
    },
];

const Complaints = observer(() => {

    useEffect(() => {
        complaintsStore.getComplaintsList()
    }, [])

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={24}>
                        <Typography.Title>Список жалоб</Typography.Title>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={complaintsStore.list} />

            </div>
        </>
    )
});

export default Complaints;