import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";

class metroController {

    list = []

    metro = {
        id: null,
        name: "",
        cityID: null
    }

    constructor() {
        makeAutoObservable(this);
    }

    getAllMetro() {
        axios.get("/user/metro")
            .then(success => {
                this.list = success.data
                console.log(success)
            });
    }

    onChangeText(item, value) {
        this.metro[item] = value
    }

    getMetroByID(metroID) {
        axios.get("/user/metro/" + metroID)
            .then(success => {
                this.metro = {
                    id: success.data.id,
                    name: success.data.name,
                    cityID: success.data.cityid
                }
                console.log(success)
            });
    }

    setMetro(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("name", this.metro.name)
        facts.append("cityid", this.metro.cityID)

        axios.post("/user/metro/" + this.metro.id, facts)
            .then(success => {
                window.location.href = "/metro";
            });
    }

    deleteMetro(metroID) {
        axios.delete("/user/metro/" + metroID)
            .then(success => {
                window.location.href = "/metro";
            })
    }

}

export default new metroController()