import metroController from "./metroController";
import { useEffect } from "react"
import {Button, Col, Input, Row, Typography, Select} from "antd";
import {observer} from "mobx-react-lite";

const MetroDetail = observer((props) => {

    useEffect(() => {
        metroController.getMetroByID(props.metroID)
    }, [])

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{metroController.metro.name}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/faq"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => metroController.setMetro(e)}>
                    <label htmlFor="">Название станции</label>
                    <Input
                        value={metroController.metro.name}
                        onChange={(e) => metroController.onChangeText("name", e.target.value)}
                        required
                    />

                    <label htmlFor="">Город</label>

                    <Select value={metroController.metro.cityID} style={{ width: 120 }} onChange={(e) => metroController.onChangeText("cityID", e)}>
                        <Select.Option value={1}>Москва</Select.Option>
                        <Select.Option value={24}>Санкт-Петербург</Select.Option>
                        <Select.Option value={328}>Екатеринбург</Select.Option>
                        <Select.Option value={206}>Нижний Новгород</Select.Option>
                        <Select.Option value={355}>Казань</Select.Option>
                        <Select.Option value={301}>Самара</Select.Option>
                        <Select.Option value={218}>Новосибирск</Select.Option>
                    </Select>

                    <br/>


                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>
            </div>
        </>
    )
})

export default MetroDetail