import {observer} from "mobx-react-lite";
import Bread from "../../components/Breadcrumb";
import React, { useEffect } from "react";
import reviewController from "./review.controller"
import {Button, Col, Input, message, Row, Typography, Upload, InputNumber} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import config from "../../config.json";

const Review = observer(({ reviewID=0 }) => {

    const breadcrumbs_list = [
        {
            title: "Список отзывов",
            url: "/reviews"
        },
        {
            title: "Редактирование отзыва",
            url: "#"
        }
    ];

    const propsImage = {
        name: 'file',
        action: config.path + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "document",
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
            }
            if (info.file.status === 'done') {
                console.log(info.file);
                reviewController.setReview("image", info.file.response.data.url)

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    if(reviewID > 0) {
        useEffect(() => {
            reviewController.getReviewByID(reviewID)
        }, [])
    }

    return(
        <>
            <Bread items={breadcrumbs_list} />

            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>Редактирование отзыва</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/reviews"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={e => reviewController.postReview(e)}>
                    <label htmlFor="">Имя</label>
                    <Input
                        value={reviewController.review.name}
                        onChange={(e) => reviewController.setReview("name", e.target.value)}
                        required
                    />

                    <label htmlFor="">Должность</label>
                    <Input
                        value={reviewController.review.vacancy}
                        onChange={(e) => reviewController.setReview("vacancy", e.target.value)}
                        required
                    />

                    <label htmlFor="">Запрос</label>
                    <Input
                        value={reviewController.review.request}
                        onChange={(e) => reviewController.setReview("request", e.target.value)}
                        required
                    />

                    <label htmlFor="">Время подбора</label>
                    <Input
                        value={reviewController.review.duration}
                        onChange={(e) => reviewController.setReview("duration", e.target.value)}
                        required
                    />

                    <label htmlFor="">Статус сделки</label>
                    <Input
                        value={reviewController.review.status}
                        onChange={(e) => reviewController.setReview("status", e.target.value)}
                        required
                    />
                    <label htmlFor="">Количетсво вариантов</label>
                    <br/>
                    <InputNumber
                        value={reviewController.review.count}
                        onChange={(e) => reviewController.setReview("count", e)}
                        required
                    />
                    <br/><br/>
                    <label htmlFor="">Текст отзыва</label>
                    <Input.TextArea
                        value={reviewController.review.review}
                        onChange={(e) => reviewController.setReview("review", e.target.value)}
                        required
                    />



                    {reviewController.review.image && reviewController.review.image !== "" &&
                        <img src={ reviewController.review.image } alt=""/>
                    }

                    <Upload {...propsImage}>
                        <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                    </Upload>

                    <Button type="primary" htmlType="submit">Сохранить</Button>
                </form>

            </div>
        </>
    )
})

export default Review