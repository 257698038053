import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";

class faqController {

    list = []

    element = {
        id: null,
        question: "",
        answer: ""
    }

    constructor() {
        makeAutoObservable(this);
    }

    getAllFaq() {
        axios.get("/user/questansw")
            .then(success => {
                this.list = success.data
                console.log(success)
            });
    }

    onChangeText(item, value) {
        this.element[item] = value
        console.log(this.element)
    }

    getFaqByID(faqID) {
        axios.get("/user/questansw/" + faqID)
            .then(success => {
                this.element = {
                    id: success.data.id,
                    question: success.data.question,
                    answer: success.data.answer
                }
                console.log(success)
            });
    }

    setFaq(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("question", this.element.question)
        facts.append("answer", this.element.answer)

        axios.post("/user/questansw/" + this.element.id, facts)
            .then(success => {

                window.location.href = "/faq";
            });
    }

    deleteFaq(faqID) {
        axios.delete("/user/questansw/" + faqID)
            .then(success => {
                window.location.href = "/faq";
            })
    }

}

export default new faqController()