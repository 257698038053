import React, {useState, useEffect} from 'react';
import {Button, Col, Row, message, Typography, Input, InputNumber} from "antd";
import axios from 'axios';
import Bread from "../../components/Breadcrumb";
import { YMaps, Map } from 'react-yandex-maps';

const breadcrumbs_list = [
    {
        title: "Список городов",
        url: "/city"
    },
    {
        title: "Редактирование города",
        url: "#"
    }
];

const { Title } = Typography;

export default function CityDetail(props) {
    const [update, setUpdate] = useState(0);
    const [city, setCity] = useState({
        id: props.cityID,
        title: 'Настройки города',
        name: '',
        display: false,
        latitude: 0,
        longitude: 0
    });


    function geocodes(result) {
        console.log(result.geoObjects.get(0).geometry.getCoordinates());
    }

    useEffect(() => {
        if(props.cityID > 0) {
            axios.get('/user/city/' + props.cityID)
                .then(response => {
                    setCity({
                        ...city,
                        name: response.data.name,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude
                    });
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        }
    }, [update]);

    const sendForm = (e) => {
        e.preventDefault();
        console.log(city);
        let facts = new FormData();
        facts.append('name', city.name);
        facts.append('latitude', city.latitude);
        facts.append('longitude', city.longitude);
        console.log(city);
        axios.post('/user/city/' + city.id, facts)
            .then(response => {
                console.log(response);
                if(props.cityID > 0) {
                    message.success('Изменения сохранены');
                } else {
                    window.location.href = "/city";
                }
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    };

    const getGeoLocation = ymaps => {
        return ymaps.geocode(city.name, { results: 1 }).then(
            res => {
                console.log(res.geoObjects.get(0).geometry.getCoordinates())
                setCity({
                    ...city,
                    latitude: res.geoObjects.get(0).geometry.getCoordinates()[0],
                    longitude: res.geoObjects.get(0).geometry.getCoordinates()[1]
                });
            }
        );
    };

    const handleApiAvaliable = ymaps => {
        const geolocation = getGeoLocation(ymaps);
    };

    const searchCity = () => {
        setCity({ ...city, display: true });
    };

    const unSearchCity = () => {
        setCity({ ...city, display: false })
    };

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Title>{city.title} {city.name}</Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/city"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={sendForm}>
                    <label htmlFor="">Наименование</label>
                    <Input
                        value={city.name}
                        onChange={(e) => setCity({
                            ...city,
                            name: e.target.value,
                            display: false
                        })}
                        required
                    />
                    <span onClick={searchCity}>Найти</span>
                    {city.display === true &&
                        <YMaps
                            query={{
                                apikey: "b9e75516-9bc0-46b3-a32d-9c219e8c409a",
                            }}
                            version={'2.1'}
                        >
                            <Map
                                state={{ center: [city.latitude, city.longitude], zoom: 9 }}
                                modules={["geolocation", "geocode"]}
                                onLoad={ymaps => handleApiAvaliable(ymaps)}
                            />
                        </YMaps>
                    }

                    <Button type="primary" htmlType="submit" disabled={city.display === true && city.latitude > 0 && city.longitude > 0 ? "" : "disabled"}>Сохранить</Button>
                </form>
            </div>
        </>
    )
}