import {makeAutoObservable} from "mobx";
import axios from "../../store/axios";
import { message } from "antd";

class reviewController {
    constructor() {
        makeAutoObservable(this)
    }

    reviews = {
        data: [],
        page: 1,
        all: 1
    }

    review = {}

    setLimit(page) {
        console.log(page)
        this.reviews.page = page
        this.getReviews()
    }

    getReviews() {
        axios.get("/review/review?page=" + this.reviews.page)
            .then(success => {
                this.reviews.data = success.data[0]
                this.reviews.all = success.data[1]
            })
            .catch(error => console.log(error))
    }

    getReviewByID(reviewID) {
        axios.get("/review/review/" + reviewID)
            .then(success => {
                this.review = success.data
            })
            .catch(error => console.log(error))
    }

    setReview(name, value) {
        this.review[name] = value
    }

    deleteReview(reviewID) {
        axios.delete("/review/review/" + reviewID)
            .then(success => {
                this.getReviews()
            })
            .catch(error => console.log(error))
    }

    postReview(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append("name", this.review.name)
        facts.append("vacancy", this.review.vacancy)
        facts.append("request", this.review.request)
        facts.append("duration", this.review.duration)
        facts.append("count", this.review.count)
        facts.append("status", this.review.status)
        facts.append("review", this.review.review)
        facts.append("image", this.review.image ? this.review.image : "")

        let url = "/review/review/"
        if(this.review.id && Number(this.review.id) > 0) {
            url = url + this.review.id
        }

        axios.post(url, facts)
            .then(success => {
                document.location.href="/reviews";
            })
            .catch(error => console.log(error))
    }

}

export default new reviewController()