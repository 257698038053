import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";
import filtersController from "../filters/filtersController";

class categoriesController {

    list = []

    element = {
        id: null,
        name: "",
        text: "",
        parentid: "0",
        photo: "",
        nameobject: 0,
        subcategory: [[], 0]
    }

    filters = [];

    constructor() {
        makeAutoObservable(this);
    }

    getAllCategories() {
        axios.get("/object/categoryobjectree/")
            .then(success => {
                this.list = success.data
                console.log(success)
            });
    }

    getParentCategories() {
        axios.get("/object/categoryobject/0")
            .then(success => {
                this.list = success.data.subcategory[0];
                console.log(success)
            });
    }

    onChangeText(item, value) {
        this.element[item] = value;
    }

    onChangeFilters(value) {
        this.filters = value
    }

    getCategoryByID(categoryID) {
        axios.get("/object/categoryobject/" + categoryID)
            .then(success => {
                this.element = success.data;
                if(success.data.parentid === 0) this.element.parentid = "0";
            });
        axios.get("/object/filterbycategory/" + categoryID)
            .then(success => {
                this.filters = [];
                success.data.map(e => {
                    if(e.id !== 0) this.filters.push(e.id)
                });
            });
    }

    setCategory(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("name", this.element.name)
        facts.append("text", this.element.text)
        facts.append("parentid", Number(this.element.parentid))
        facts.append("photo", this.element.photo)
        facts.append("nameobject", Number(this.element.nameobject))
        facts.append("search", this.element.search)
        if(this.filters.length > 0){
            this.filters.map(i => facts.append("filtercatid[]", i))
        } else {
            facts.append("filtercatid[]", "");
        }

        let url;
        if(this.element.id){
            url = "/object/categoryobject/" + this.element.id
        } else {
            url = "/object/categoryobject/";
        }

        axios.post(url, facts)
            .then(success => {
                window.location.href = "/categories";
            });
    }

    deleteCategory(categoryID) {
        axios.delete("/object/categoryobject/" + categoryID)
            .then(success => {
                window.location.href = "/categories";
            })
    }

}

export default new categoriesController()