import { makeAutoObservable, makeObservable } from "mobx";
import axios from "./axios";
import config from "../config.json"

class advertStore {

    searchCityName = ""

    filter = {
        city: 0,
        type_advert: 0,
        type_object: 0,
        room: [],
        status: 1,
        region: 0,
        price_from: 0,
        price_to: 0,
        limit: 10,
        offset: 0
    }

    types = {
        type: [],
        region: [],
        room: [],
        typeProperty: [],
        city: []
    }

    cities = []

    adverts = []

    modal = {
        id: 0,
        display: false,
        text: ""
    }

    limit = 10;
    page = 1;
    count = -1;

    adminList = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetchTypes() {
        axios.get("/object/filterdata")
            .then(success => {
                axios.get('/user/city')
                    .then(response => {
                        this.cities = response.data;
                    });

                let array = [];
                if(success.data.typeproperty.length > 0) {
                    success.data.typeproperty.map(item => {
                        array = [...array, {
                            ...item,
                            name: JSON.parse(item.name)
                        }];
                    })
                }

                this.types = {
                    type: success.data.type,
                    region: success.data.region,
                    room: success.data.room,
                    typeProperty: array
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchAll() {
        let url = "/object/object?viewmessage=1&limit=" + this.limit + "&page=" + this.page;

        if(this.filter.city > 0) {
            url = url + "&filter[cityid]=" + this.filter.city;
        }

        if(this.filter.region > 0) {
            url = url + "&filter[regionid]=" + this.filter.region;
        }

        if(this.filter.type_advert > 0) {
            url = url + "&filter[typeid]=" + this.filter.type_advert;
        }
        if(this.filter.type_object > 0) {
            url = url + "&filter[typepropertyid]=" + this.filter.type_object;
        }

        if(this.filter.room.length > 0) {
            this.filter.room.map(item => {
                url = url + "&filter[roomid]=" + item;
            });
        }

        if(this.filter.status !== null) {
            url = url + "&filter[status]=" + this.filter.status;
        }

        if(this.filter.price_from >= 0) {
            url = url + "&filter[pricefrom]=" + this.filter.price_from;
        }

        if(this.filter.price_to > this.filter.price_from) {
            url = url + "&filter[priceto]=" + this.filter.price_to;
        }

        axios.get(url)
            .then(success => {
                console.log(success);
                if(success.data.items) {
                    this.adverts = success.data.items;
                    this.count = Number(success.data.pagination[1]);
                    this.page = Number(success.data.pagination[0]);
                } else {
                    this.adverts = [];
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    clearFilter() {
        this.filter = {
            city: 0,
            type_advert: 0,
            type_object: 0,
            room: [],
            status: null,
            region: 0,
            price_from: 0,
            price_to: 0,
            limit: 10,
            offset: 0
        }
        this.fetchAll()
    }

    acceptAdvert(advertID) {
        let facts = new FormData();
        facts.append("status", 3);

        axios.post("/object/objectdmin/" + advertID, facts)
            .then(success => {
                console.log(success);
                this.fetchAll()
            })
            .catch(error => console.log(error));
    }

    sendModal() {
        let facts = new FormData();
        facts.append("status", 0);
        facts.append("texterror", this.modal.text);

        axios.post("/object/objectdmin/" + this.modal.id, facts)
            .then(success => {
                console.log(success)
                this.fetchAll()
                this.closeModal()
            })
            .catch(error => console.log(error));
    }

    openModal(ID) {
        this.modal = {
            ...this.modal,
            id: ID,
            display: true
        }
    }

    closeModal() {
        this.modal = {
            id: 0,
            display: false,
            text: ""
        }
    }

    changeFilter(name, value) {
        this.filter[name] = value;
        if(name === "type_object") {
            this.filter.room = [];
        }
    }

    setErrorText(text) {
        this.modal.text = text;
    }

    setPage(page) {
        this.page = page;
        this.fetchAll()
    }

    setLimit(limit) {
        this.limit = limit;
        this.fetchAll();
    }

    filterCategories = [];

    getFilterCategory(id) {
        axios.get("/object/filtercategory/" + id)
            .then(success => {
                if(success.data.nameobject === 1) this.filterCategories.push(success.data);
            })
    }

    filterNames = [];

    getFiltersNames(categoryID) {
        axios.get("/object/filtervalue/" + categoryID)
            .then(success => {
                this.filterNames = success.data[0];
            });
    }

    getFilterName(id) {
        let filter = this.filterNames.find(el => el.id === id);
        if(filter){
            return filter.label;
        }
    }

    getName(item) {
        let name = "";
        if(item.name !== "" && item.parentid !== 0){
            return item.name;
        } else {
            if(this.types.type.length > 0){
                name += this.types.type.find(el => el.id === item.typeid).name
            }
            if(this.filterCategories.length > 0){
                this.filterCategories.map(f => {
                    let filter = item.filteradvert.find(el => el.filtercategoryid === f.id);
                    if(filter){
                        let filterID = filter.filtervalueid;
                        let filterName = this.getFilterName(filterID);
                        if(!name.includes(filterName)) name += " " + filterName;
                    }
                })
            }
            return name;
        }
    }

    getAdminUsers() {
        axios.get("/user/userlist?filter[phone]=7 950-195-85-55")
            .then(success => {
                console.log(success)
                this.adminList = success.data;
            });
    }

    searchCity(e) {
        axios.get('/user/city?letter=' + e)
            .then(response => {
                console.log(response.data)
                this.cities = response.data;
            });
    }

}

export default new advertStore();