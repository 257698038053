import React from 'react';

import Home from "./pages/Home";
import CityList from "./pages/city/CityList";
import CityDetail from "./pages/city/CityDetail";
import UsersList from "./pages/users/UsersList";
import AdminList from "./pages/users/AdminList";
import AdminDetail from "./pages/users/AdminDetail";
import AdvertList from "./pages/advert/AdvertList";
import RegionList from "./pages/region/RegionList";

import SignIn from "./pages/enter/Signin";

import Room from "./pages/props/Room";
import PropAdvert from "./pages/props/Advert";

import NewsList from "./pages/news/NewsList";
import NewsDetail from "./pages/news/NewsDetail";

import Objects from "./pages/props/Objects";

import store from "store";
import AdvertDetail from "./pages/advert/Advert";
import Chat from "./pages/Chat";
import Complaints from "./pages/complaints/Complaints"
import Permission from "./components/Permission";
import Faq from "./pages/faq/Faq";
import FaqDetail from "./pages/faq/FaqDetail";

import Metro from "./pages/metro/Metro";
import MetroDetail from "./pages/metro/MetroDetail";

import Documents from "./pages/documents/Documents";
import Document from "./pages/documents/Document";
import Review from "./pages/reviews/Review";
import Reviews from "./pages/reviews/Reviews";

import Category from "./pages/categories/Category";
import Categories from "./pages/categories/Categories";

import FiltersList from "./pages/filters/FiltersList";
import FiltersCategory from "./pages/filters/FiltersCategory";
import Filter from "./pages/filters/Filter";

import SlidersList from "./pages/sliders/SlidersList";
import Slider from "./pages/sliders/Slider";
import Banner from "./pages/sliders/Banner";

const nowPath = window.location.pathname;
const url = nowPath.split("/");

if(store.get('user') && store.get('user').token !== "") {
    console.log(store.get('user'));
} else {
    if(url[1] !== "login") {
        window.location = "/login";
    }
}

const Routes = {
    "/login": () => <SignIn />,

    "/": () => <Home />,
    "/city": () => store.get("user").permission === "admin" ? <CityList /> : <Permission />,
    "/city/:id": ({id}) => store.get("user").permission === "admin" ? <CityDetail cityID={id} /> : <Permission />,
    "/city/:cityID/regions": ({cityID}) => store.get("user").permission === "admin" ? <RegionList cityID={cityID} /> : <Permission />,

    "/users": () => store.get("user").permission === "admin" ? <UsersList /> : <Permission />,
    "/admin": () => store.get("user").permission === "admin" ? <AdminList /> : <Permission />,
    "/admin/:id": ({id}) => store.get("user").permission === "admin" ? <AdminDetail userID={id} /> : <Permission />,
    "/advert": () => <AdvertList />,
    "/advert/:id": ({id}) => <AdvertDetail advertID={id} />,
    "/signin": () => <SignIn />,

    //"/props/room": () => <Room />,
    "/props/advert": () => <PropAdvert />,
    //"/props/type": () => <Objects />,

    "/news": () => store.get("user").permission === "admin" ? <NewsList /> : <Permission />,
    "/news/new": () => store.get("user").permission === "admin" ? <NewsDetail /> : <Permission />,
    "/news/:id": ({id}) => store.get("user").permission === "admin" ? <NewsDetail newsID={id} /> : <Permission />,
    "/chat/:advertID": ({advertID}) => <Chat advertID={advertID}/>,
    "/complaints": () => <Complaints />,

    // "/faq": () => <Faq />,
    // "/faq/:faqID": ({faqID}) => <FaqDetail faqID={faqID} />,

    "/metro": () => <Metro />,
    "/metro/:metroID": ({metroID}) => <MetroDetail metroID={metroID} />,

    // "/documents": () => <Documents />,
    // "/documents/:documentID": ({documentID}) => <Document documentID={documentID} />,

    // "/reviews": () => <Reviews />,
    // "/reviews/:reviewID": ({reviewID}) => <Review reviewID={reviewID} />,

    "/categories": () => <Categories />,
    "/categories/:categoryID": ({categoryID}) => <Category categoryID={categoryID} />,

    "/filters": () => <FiltersList />,
    "/filters/:filterID": ({filterID}) => <FiltersCategory filterID={filterID} />,
    "/filter/:categoryID/:filterID": ({categoryID, filterID}) => <Filter categoryID={categoryID} filterID={filterID} />,

    "/sliders": () => <SlidersList />,
    "/sliders/:sliderID": ({sliderID}) => <Slider sliderID={sliderID} />,
    "/sliders/:sliderID/banner/:bannerID": ({sliderID, bannerID}) => <Banner sliderID={sliderID} bannerID={bannerID} />,
};
export default Routes;