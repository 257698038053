import slidersController from "./slidersController";
import { useEffect } from "react"
import {Button, Col, Input, Row, Typography, Select, Checkbox, Space, Table} from "antd";
import {observer} from "mobx-react-lite";
import config from "../../config.json";
import {UploadOutlined} from "@ant-design/icons";
import categoriesController from "../categories/categoriesController";

const Slider = observer((props) => {
    useEffect(() => {
        if(Number(props.sliderID) > 0) slidersController.getSlider(Number(props.sliderID));
        categoriesController.getAllCategories()
    }, []);

    let pageTitle = (Number(props.sliderID) > 0) ? "Редактирование слайдера" : "Добавить новый слайдер"

    const columns = [
        {
            title: 'Активность',
            key: 'active',
            width: 100,
            render: (text, record) => {
                if(record.active === 1){
                    return "Да";
                } else {
                    return "Нет";
                }
            },
        },
        {
            title: 'Изображение',
            key: 'image',
            width: 220,
            render: (text, record) => (
                <a href={"/sliders/" + Number(props.sliderID) + "/banner/" + record.id}>
                    <img src={record.photo} alt="" width={200} />
                </a>),
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href={"/sliders/" + Number(props.sliderID) + "/banner/" + record.id}>{text}</a>,
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/sliders/" + Number(props.sliderID) + "/banner/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => slidersController.deleteBanner(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    return(
        <>
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={16}>
                        <Typography.Title>{pageTitle} {slidersController.slider.name}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="dashed" href={"/sliders"}>Вернуться к списку</Button>
                    </Col>
                </Row>

                <form onSubmit={(e) => slidersController.setSlider(e)}>
                    <label htmlFor="">Название</label>
                    <Input
                        value={slidersController.slider.name}
                        onChange={(e) => slidersController.onChange("name", e.target.value)}
                        required
                    />

                    <br/><br/>

                    <Checkbox
                        checked={slidersController.slider.active}
                        onChange={(e) => slidersController.onChange("active", e.target.checked)}
                    >Активен</Checkbox>

                    <br/><br/>

                    <Checkbox
                        checked={slidersController.slider.typeview}
                        onChange={(e) => slidersController.onChange("typeview", e.target.checked)}
                    >Автопрокрутка баннеров</Checkbox>

                    <br/><br/>

                    <label htmlFor="">Место показа</label>
                    <Select
                        value={slidersController.slider.place}
                        style={{ width: 300 }}
                        onChange={(e) => slidersController.onChange("place", e)}
                    >
                        <Select.Option value="home">Главная</Select.Option>
                        <Select.Option value="adverts">Возле объявлений на главной</Select.Option>
                        {categoriesController.list.map(e => {
                            return <Select.Option value={"category" + e.id + "s"}>Категория: {e.name}</Select.Option>
                        })}
                    </Select>

                    <br/><br/>

                    {Number(props.sliderID) > 0 &&
                        <>
                            <div className="admin">
                                <Row justify="space-around" align="middle">
                                    <Col span={12}>
                                        <Typography.Title level={3}>Баннеры</Typography.Title>
                                    </Col>
                                    <Col span={12} align={'right'}>
                                        <Button type="primary" href={"/sliders/" + Number(props.sliderID) + "/banner/0"}>Добавить</Button>
                                    </Col>
                                </Row>

                                <Table
                                    columns={columns}
                                    dataSource={slidersController.slider.baner[0]}
                                    pagination={false}
                                />

                            </div>
                        </>
                    }

                    <br/><br/>

                    <Button type="primary" htmlType="submit">Сохранить</Button>

                </form>
            </div>
        </>
    )

});

export default Slider