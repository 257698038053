import { makeAutoObservable, makeObservable } from "mobx";
import axios from "./axios";
import store from "store";

class ComplaintsStore {

    limit = 50;
    offset = 0;
    list = [];

    constructor() {
        makeAutoObservable(this);
    }

    getComplaintsList() {
        axios.get("/user/message?filter[type]=0&filter[status]=1&getuser=1&limit=" + this.limit + "&offset=" + this.offset)
            .then(success => {
                console.log(success);
                this.list = success.data[0];
                if(success.data[1] > this.limit && success.data[1] > this.offset + this.limit) {
                    this.setOffset(this.offset + this.limit);
                }
            })
            .catch(error => console.log(error));
    }

    setLimit(limit) {
        this.limit = limit;
    }

    setOffset(offset) {
        this.offset = offset;
    }

}

export default new ComplaintsStore();