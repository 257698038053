import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";

class slidersController {
    list = [];

    slider = {
        id: null,
        name: "",
        active: false,
        text: "",
        place: "home",
        typeview: 1,
        baner: []
    }

    banner = {
        id: null,
        name: "",
        active: true,
        sliderid: 0,
        place: "",
        typelink: "url",
        link: "",
        photo: "",
        text: ""
    }

    constructor() {
        makeAutoObservable(this);
    }

    onChange(item, value) {
        this.slider[item] = value
        //console.log(this.slider)
    }

    getAllSliders() {
        axios.get("/object/slider/")
            .then(success => {
                this.list = success.data[0]
                console.log(success)
            });
    }

    getSlider(id) {
        axios.get("/object/slider/" + id)
            .then(success => {
                this.slider = success.data
                console.log(success)
            });
    }

    setSlider(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("name", this.slider.name)
        facts.append("active", Number(this.slider.active))
        facts.append("typeview", Number(this.slider.typeview))
        facts.append("text", this.slider.text)
        facts.append("place", this.slider.place)

        let url;
        if(this.slider.id){
            url = "/object/slider/" + this.slider.id
        } else {
            url = "/object/slider/";
        }

        axios.post(url, facts)
            .then(success => {
                console.log(success);
                window.location.href = "/sliders";
            })
            .catch(e => console.log(e.response));
    }

    deleteSlider(id) {
        axios.delete("/object/slider/" + id)
            .then(success => {
                window.location.href = "/sliders";
            })
    }

    onChangeBanner(item, value) {
        this.banner[item] = value
    }

    getBanner(id) {
        axios.get("/object/poster/" + id)
            .then(success => {
                this.banner = success.data
            });
    }

    setBanner(e) {
        e.preventDefault()
        let facts = new FormData()
        facts.append("name", this.banner.name)
        facts.append("active", Number(this.banner.active))
        facts.append("text", this.banner.text)
        facts.append("photo", this.banner.photo)
        facts.append("sliderid", this.banner.sliderid)
        facts.append("link", this.banner.link)

        let url;
        if(this.banner.id){
            url = "/object/poster/" + this.banner.id
        } else {
            url = "/object/poster/";
        }

        axios.post(url, facts)
            .then(success => {
                window.location.href = "/sliders/" + this.banner.sliderid;
            })
            .catch(e => console.log(e.response));
    }

    deleteBanner(id) {
        axios.delete("/object/poster/" + id)
            .then(success => {
                window.location.href = "/sliders/" + this.banner.sliderid;
            })
    }
}

export default new slidersController();