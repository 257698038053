import {observer} from "mobx-react-lite";
import Bread from "../../components/Breadcrumb";
import {Button, Col, Row, Space, Table, Typography, Pagination} from "antd";
import React, { useEffect } from "react";
import reviewController from "./review.controller"

const Reviews = observer(() => {

    const breadcrumbs_list = [
        {
            title: "Список отзывов",
            url: "/reviews"
        }
    ];

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a href={"/reviews/" + record.id}>Изменить</a>
                    <Button type="link" onClick={() => reviewController.deleteReview(record.id)}>Удалить</Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        reviewController.getReviews()
    }, [])

    return(
        <>
            <Bread items={breadcrumbs_list} />
            <div className="admin">
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <Typography.Title>Список отзывов</Typography.Title>
                    </Col>
                    <Col span={12} align={'right'}>
                        <Button type="primary" href={"/reviews/0"}>Создать</Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={reviewController.reviews.data} pagination={false} />

                <Pagination current={reviewController.reviews.page} total={reviewController.reviews.all} showSizeChanger={false} onChange={e => reviewController.setLimit(e)} />
            </div>
        </>
    )
})

export default Reviews