import { makeAutoObservable, makeObservable } from "mobx";
import axios from "./axios";
import store from "store";
import {message} from "antd";

class TypeStore {

    props = {
        room: [],
        type: [],
        typeProperty: []
    }

    element = {
        id: 0,
        name: "",
        typePropertyID: null
    };

    displayModal = false;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        console.log(value);
        this.element[name] = value;
    }

    getRooms() {
        axios.get('/object/room')
            .then(response => {
                console.log(response);
                this.props.room = response.data;
            })
            .catch(error => {
                message.error(error);
            });
    }

    openModal(id=0) {
        if(id > 0) {
            axios.get('/object/room/' + id)
                .then(response => {
                    console.log(response);
                    this.element = {
                        ...this.element,
                        id: id,
                        name: response.data.name,
                        typePropertyID: response.data.typepropertyid
                    };
                })
                .catch(error => {
                    message.error(error);
                });
        }
        this.displayModal = true;
    }

    closeModal() {
        this.element = {
            id: 0,
            name: "",
            typePropertyID: null
        };
        this.displayModal = false;
    }

    getTypeProperty() {
        axios.get("/object/typeproperty")
            .then(response => {
                let array = [];
                response.data.map(item => {
                    item.name = JSON.parse(item.name);
                    array = [...array, item];
                });
                this.props.typeProperty = array;
            })
            .catch(error => {
                message.error(error);
            });
    }

    sendForm() {
        if(this.element.name !== "") {
            if(this.element.typePropertyID > 0) {
                let facts = new FormData();

                let json;

                facts.append("name", this.element.name);
                facts.append("typepropertyid", this.element.typePropertyID);
                let url = "/object/room";

                if(this.element.id > 0) {
                    url = "/object/room/" + this.element.id;
                }

                axios.post(url, facts)
                    .then(success => {
                        this.closeModal();
                        this.getRooms();
                    })
                    .catch(error => console.log(error));
            } else {
                message.error("Вы не выбрали тип недвижимости");
            }

        } else {
            message.error("Невозможно добавить пустое значение");
        }
    }
}

export default new TypeStore();